import React from 'react';
import logo from './logo.svg';
import './styles/app.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Dummaloop's Dev Domain</h1>
        <h2>Star Citizen</h2>
        <ul>
          <li><a className="App-link" href="https://me.dummaloop.com/starcitizen/maps/Stanton_System_Map_v5-1440p.jpg" target="_blank" rel="noopener noreferrer">Stanton System Map 1440p</a></li>
          <li><a className="App-link" href="https://me.dummaloop.com/starcitizen/maps/Stanton_System_Map_v5-4k.jpg" target="_blank" rel="noopener noreferrer">Stanton System Map 4k</a></li>
          <li><a className="App-link" href="https://me.dummaloop.com/starcitizen/maps/Stanton_System_Map_v5-8k.jpg" target="_blank" rel="noopener noreferrer">Stanton System Map 8k</a></li>
        </ul>
        <h2>OpenCar</h2>
        <ul>
          <li><a className="App-link" href="https://me.dummaloop.com/clients/opencar/simulator-prototype/" target="_blank" rel="noopener noreferrer">OpenCar Simulator Prototype</a></li>
          <li><a className="App-link" href="https://me.dummaloop.com/clients/opencar/index.html" target="_blank" rel="noopener noreferrer">OpenCar dev examples</a></li>
        </ul>
      </header>
    </div>
  );
}

export default App;

//<img src={logo} className="App-logo" alt="logo" />
